<template>
  <div class="WJgroupIntroduce">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="@/assets/image/guanlibanner.png" alt="">
        </div>
      </el-col>
    </el-row>
    <div>
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class=" WJgroupIntroduce-Box" type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div class="padding-tb70">
            <div class="WJgroup-titleBox flex">
              <p class="WJgroup-title">{{news.typename}}</p>
            </div>
            <div class="flex sendWord-warp">
              <div class="sendWordleft">
                <!-- <img class="swImg1" src="../../../assets/image/SW1.png" alt=""> -->
                <div class="sendWordleftC">
                  <div v-html="news.content"></div>
                </div>
                <img class="swImg2" src="../../../assets/image/SW2.png" alt="">
                <img class="swImg3" src="../../../assets/image/SW3.png" alt="">
              </div>
              <div class="flex1 flex justify_center hidden-xs">
                  <div>
                      <img class="cmImg" src="../../../assets/image/CHAIRMANSMESSAGE.png" alt="">
                      <p class='cmFont'>{{news.typename}}</p>
                  </div>
              </div>
            </div>

          </div>


        </el-col>

      </el-row>
    </div>
    <el-row>

      <el-col>
        <footerb></footerb>
      </el-col>

    </el-row>
    <!-- 底部 -->

  </div>
</template>

<script>
  import CountTo from 'vue-count-to'
  export default {
    name: "WJgroupIntroduce",
    components: {
      CountTo
    },
    data() {
      return {
        menuActiveIndex: '/WJgroupSendWord',
        menu: {
         name: '维佳集团',
         path: '/WJgroupIntroduce',
          list: [{
            name: this.$t('menu.Introduction'),
            path: '/WJgroupIntroduce',
          }, {
            name: this.$t('menu.Message'),
            path: '/WJgroupSendWord',
          },{
            name: this.$t('menu.develop'),
            path: '/WJgroupDevelop',
          }, {
            name:this.$t('menu.honour'),
            path: '/WJgroupHonor',
          }, {
            name:this.$t('menu.team'),
            path: '/WJgroupTeam',
          }]
        },
        breadcrumb: [{
          name:this.$t('menu.group'),
          path: '/WJgroupIntroduce',
        }, {
          name: this.$t('menu.Message'),
          path: '/WJgroupIntroduce',
        }],
        onActive: 1,
        news:{},
        typeid:138
      };
    },

    created() {
      this.getData();
    },
    mounted() {
      new this.$wow.WOW().init()
    },
    methods: {
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        this.$request.post(api,{
          apiType_1:'ekey=1&typeid='+this.typeid+'&addfields=content,content_en',//获取栏目详情
        }).then(res=>{
          this.news =this.$utils.translate(res.apiType[1].data);
        })
      },
      choseOne(e) {
        this.onActive = e
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .sendWordleft {
    margin-top: 68px;
    width: 53.5%;
    min-height: 622px;
    background: #eff0f2;
    padding: 90px 0 130px 130px;
    position: relative;
  }
  .sendWordleftC  P{
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1px;
    color: #414141;
  }
  .sendWordleftC1{
      margin-top: 40px;
  }
  .swImg1{
    position: absolute;
    top:0;
    left: 0;
  }
  .swImg2{
    position: absolute;
    bottom:50px;
    right: 50px;
  }
  .swImg3{
    position: absolute;
    bottom:152px;
    left: 32px;
  }

  .cmImg{
    width: 205px;
    height: 66px;
  }
  .cmFont{
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #707070;
    margin-top: 27px;
  }
  @media screen and (min-width:320px) and (max-width:767px) {
    .WJgroupIntroduce .sendWord-warp{
      display: block;
    }
    .WJgroupIntroduce  .sendWordleft{
      padding: 40px 20px;
      width: calc(100% - 40px);
      padding-bottom: 360px;
    }
    .WJgroupIntroduce  .swImg2{
      bottom:80px;
    }
    .WJgroupIntroduce  .swImg3{
      bottom:50px;
    }
    .sendWordleftC P{
      font-size: 37.5px!important;
    }
  }
</style>
